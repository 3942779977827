<template>
  <div class="google-map">
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
        :google="google"
        :map="map"
        :autocomplete="autocomplete"
        :placesService="placesService"
        :sessionToken="sessionToken"
      />
    </template>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import { mapSettings } from '@/constants/mapSettings';
import States from '@/assets/json/us_states.json';

export default {
  name: 'MapLoader',
  props: {
    apiKey: String,
  },
  data() {
    return {
      google: null,
      map: null,
      autocomplete: null,
      placesService: null,
      sessionToken: null,
      updateChargerListExpand: false,
      updateChargerListTimeout: null,
    };
  },
  async mounted() {
    if (!window.PRERENDER_INJECTED) {
      if (!this.googleInstance) {
        const loader = new Loader({
          apiKey: this.apiKey,
          libraries: ['places', 'geometry', 'marker'],
        });

        this.google = await loader.load();
      } else {
        this.google = this.googleInstance;
      }
    }
    this.$root.$on('updateChargerList', (expandStationList) => {
      this.updateChargerListDebounce(expandStationList);
    });
    this.$root.$on('uniqueLocationSet', () => {
      this.uniqueLocationSet();
    });
    this.initializeMap();
    this.handleLocationParameters();
  },
  beforeDestroy() {
    this.$store.dispatch('map/updateMapZoomAndCenter', {
      zoom: this.map.getZoom(),
      center: this.map.getCenter().toJSON(),
    });
    this.google.maps.event.removeListener(this.idleListener);
  },

  methods: {
    initializeMap() {
      // Prevent Roboto font from loading on map
      const head = document.getElementsByTagName('head')[0];
      const { insertBefore } = head;
      head.insertBefore = (newElement, referenceElement) => {
        if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) {
          return;
        }
        insertBefore.call(head, newElement, referenceElement);
      };

      const mapContainer = this.$refs?.googleMap;
      this.map = new this.google.maps.Map(mapContainer, {
        ...mapSettings,
        center: { lat: 38, lng: -96 },
        zoom: this.mapZoom,
        mapId: 'd7417f31225726f',
      });

      this.sessionToken = new this.google.maps.places.AutocompleteSessionToken();
      this.autocomplete = new this.google.maps.places.AutocompleteService();
      this.placesService = new this.google.maps.places.PlacesService(this.map);

      this.idleListener = this.google.maps.event.addListener(this.map, 'idle', () => {
        this.$store.dispatch('map/setZoom', this.map.getZoom());
        this.$root.$emit('updateChargerList');
      });

      this.$store.commit('map/setGoogleAutocomplete', this.autocomplete);
      this.$store.commit('map/setGoogleInstance', this.google);
      this.$store.commit('map/setGoogleMapInstance', this.map);
      this.$store.commit('map/setGooglePlacesService', this.placesService);
      this.$store.commit('map/setGoogleSessionToken', this.sessionToken);

      // Persist the search query when navigatiing away and back to the LaC page
      if (this.searchInput && !this.$route.query.search) {
        this.$router.push({ name: 'locate-charger-en', query: { search: this.searchInput } });
        // If there isn't a search query persist the state of the previously selected station
      } else if (this.uniqueLocation && !this.searchInput) {
        this.$root.$emit('stationDetailOpen', this.uniqueLocation);
      }
      // Wait to set the search input to the route query parameter until Google API is initialized
      this.$store.commit('map/setSearchInput', this.$route.query.search);
    },
    handleLocationParameters() {
      if (this.$route.params.state && !this.$route.params?.id) {
        // if the state param is input with a dash, remove the dash before passing it to getPlacePredictions
        const state = this.$route.params.state.replace(/-/g, ' ');
        const stateAbbr = States.filter(
          (p) => p.name.toLowerCase() === state.toLowerCase() || p.abbreviation.toLowerCase() === state.toLowerCase()
        );
        if (stateAbbr.length) {
          this.$root.$emit('zoomToCityorState', stateAbbr);
        } else {
          this.$router.push({ name: `locate-charger-en` });
        }
      } else if (this.$store.state.map.locationID) {
        this.$store.dispatch('map/goToLocation', this.$store.state.map.locationID).then(() => {
          this.$nextTick(() => {
            this.$store.commit('map/setIsSearchActive', false);
            this.$root.$emit('expandStationList');
          });
        });
        this.$store.commit('map/setLocationID', null);
      }
    },
    // If a specific marker is deeplinked to, it is set to uniqueLocation
    // We dont know the location until after the markers are loaded, so once its set navigate and open the details
    uniqueLocationSet() {
      if (this.uniqueLocation) {
        this.map.setCenter({ lat: this.uniqueLocation.position.lat, lng: this.uniqueLocation.position.lng });
        this.map.setZoom(15);
        this.$root.$emit('stationDetailOpen', this.uniqueLocation);
      }
    },

    /**
     * Update Charger List Debounce
     *
     * Interin function before triggering updateChargerList. Ensures that the list
     * is only triggered to update once at any given time. There are several triggers
     * for an update and they often happen together (map zoom and center changes, for example).
     *
     * @param {*} expandStationList
     */
    updateChargerListDebounce(expandStationList) {
      this.updateChargerListExpand = this.updateChargerListExpand === true || expandStationList;
      clearTimeout(this.updateChargerListTimeout);
      this.updateChargerListTimeout = setTimeout(() => {
        this.updateChargerList(this.updateChargerListExpand);
        this.updateChargerListExpand = false;
        this.updateChargerListTimeout = null;
      }, 150);
    },

    /**
     * Update Charger List
     *
     * Triggers an update of the list of chargers that are in the current bounding box.
     * Only calculates a new set of visibleMarkers if the list is currently visible or
     * if the parameter expandStationList is set to true.
     *
     * @param {Boolean} expandStationList
     */
    updateChargerList(expandStationList = false) {
      const visibleMarkers = [];
      this.$store.commit('map/setMarkersLoading', true);
      // This is an expensive computation, only perform it if we're showing the charger list
      if (expandStationList === true || this.$store.state.map.isStationListExpanded === true) {
        if (this.map) {
          const bounds = this.map.getBounds();
          this.sortedMarkers.forEach((marker) => {
            if (bounds?.contains(marker.position)) {
              visibleMarkers.push(marker);
            }
          });
          this.visibleMarkers = visibleMarkers;
          this.$store.commit('map/setVisibleMarkers', this.visibleMarkers);

          // This code below is triggered when users are zoomed far in, and have no visible chargers
          // It zooms out one 'level', and updates the charger list to display the now visible chargers
          // It wont zoom out past 200 mile radius

          if (this.visibleMarkers.length === 0) {
            this.$store.dispatch('map/setZoom', this.$store.state.map.mapZoom - 1);

            if (this.$store.state.map.mapZoom >= 8) {
              // 8 is the 200 miles /300 km radius zoom level
              this.updateChargerListTimeout = setTimeout(() => {
                this.updateChargerList(true);
                this.updateChargerListTimeout = null;
              }, 1000); // Why is this 1000 compared to 150 the other spot this setTimeout is used?
            }
          }
        }
        this.$store.commit('map/setMarkersLoading', false);
        this.$nextTick(() => {
          if (expandStationList === true) {
            this.$store.commit('map/setIsStationListExpanded', true);
          }
        });
      }
    },
  },
  computed: {
    uniqueLocation() {
      return this.$store.state.locations.uniqueLocation;
    },
    currentLocCoords() {
      return this.$store.state.map.currentLocCoords;
    },
    /**
     * Filtered Markers
     *
     * Filtering out markers on the drawer pull out in the Locate a Charger page.
     *
     * This checks the Connector filters (CCS, CHAdeMO, & L2) first and sets `isActiveConnector`,
     * if all are unchecked we default back to true to avoid no map pins being displayed.
     *
     * This checks the Station filters (Live, Commercial, & Coming Soon) second and sets `isActiveStation`,
     * if all are unchecked we default back to true to avoid no map pins being displayed.
     *
     * The result looks for both to be truthy, if not the location marker is set to
     * inactive and is filtered out in the resulting filteredMarkers response.
     */
    filteredMarkers() {
      return this.markers.filter((marker) => {
        const isActiveConnector =
          // If no connector filters are active, default to true
          (!this.$store.state.map.filters.ccsDC &&
            !this.$store.state.map.filters.ccsChademoDC &&
            !this.$store.state.map.filters.l2) ||
          // If all connector filters are active, default to true
          (this.$store.state.map.filters.ccsDC &&
            this.$store.state.map.filters.ccsChademoDC &&
            this.$store.state.map.filters.l2) ||
          // If coming soon filter is active and all marker connectors are false then default to true (likely a coming soon locaiton with no connectors defined)
          (this.$store.state.map.filters.comingSoon && !marker.isCcs && !marker.isChademo && !marker.isL2) ||
          // If CCS filter is on, show all non-comingSoon markers with CCS active
          (this.$store.state.map.filters.ccsDC && marker.isCcs && !marker.comingSoon) ||
          // If CHAdeMO filter is on, show all non-comingSoon markers with CHAdeMO active
          (this.$store.state.map.filters.ccsChademoDC && marker.isChademo && !marker.comingSoon) ||
          // If L2 filter is on, show all non-comingSoon markers with L2 active
          (this.$store.state.map.filters.l2 && marker.isL2 && !marker.comingSoon);

        const isActiveStation =
          // If no station filters are active, default to true
          (!this.$store.state.map.filters.commercial &&
            !this.$store.state.map.filters.comingSoon &&
            !this.$store.state.map.filters.siteIsLive) ||
          // If all station filters are active, default to true
          (this.$store.state.map.filters.commercial &&
            this.$store.state.map.filters.comingSoon &&
            this.$store.state.map.filters.siteIsLive) ||
          // If commercial filter
          (this.$store.state.map.filters.commercial && marker.isCommercialChargerLocation) ||
          // If "coming soon"
          (this.$store.state.map.filters.comingSoon && marker.comingSoon) ||
          // If the site is live
          (this.$store.state.map.filters.siteIsLive && !marker.comingSoon && !marker.isCommercialChargerLocation);

        const isActive = isActiveStation && isActiveConnector;
        marker.active = isActive;
        return isActive;
      });
    },
    markers() {
      return this.$store.state.locations.locationMarkers;
    },
    sortedMarkers() {
      if (this.currentLocCoords) {
        const sorted = this.filteredMarkers.slice();
        return sorted.sort((a, b) => a.distance - b.distance);
      }
      return this.filteredMarkers;
    },
    mapZoom() {
      return this.$store.state.map.mapZoom;
    },
    searchInput() {
      return this.$store.state.map.searchInput;
    },
    googleInstance() {
      return this.$store.state.map.googleInstance;
    },
  },
  watch: {
    async currentLocCoords() {
      const origin = new this.google.maps.LatLng(this.currentLocCoords);
      this.directionsService = new this.google.maps.DirectionsService();
      this.filteredMarkers.forEach((marker) => {
        if (this.currentLocCoords) {
          const destination = new this.google.maps.LatLng(marker.position);
          const distanceInMeters = this.google.maps.geometry.spherical.computeDistanceBetween(origin, destination);
          const distanceInMiles = Math.round((distanceInMeters / 1609.34) * 1.1);
          if (distanceInMeters < 50) {
            // Use Google Directions Service for small distances
            this.directionsService.route(
              {
                origin,
                destination,
                travelMode: 'DRIVING',
              },
              (result, status) => {
                if (status === 'OK') {
                  marker.distance = Math.round(result.routes[0].legs[0].distance.value / 160.934) / 10;
                } else {
                  marker.distance = distanceInMiles;
                }
              }
            );
          } else {
            marker.distance = distanceInMiles;
          }
        } else {
          marker.distance = null;
        }
      });
    },
    filteredMarkers() {
      this.$nextTick(() => {
        this.$root.$emit('updateChargerList');
      });
    },
    uniqueLocation() {
      this.$root.$emit('uniqueLocationSet');
    },
  },
};
</script>
<style scoped lang="scss">
.google-map {
  height: 100%;
  margin: auto;
  position: relative;
}
</style>
